export default {
    root: {
        name: '/',
        displayName: 'navigationRoutes.home',
    },
    routes: [{
            name: 'dashboard',
            roles: ["super_admin", "admin", "editor"],
            displayName: 'menu.dashboard',
            meta: {
                icon: "fa fa-tachometer",
            },
        },
        {
            name: 'my_champ',
            roles: ["editor"],
            displayName: 'menu.my_champ',
            meta: {
                icon: "fa fa-trophy",
            },
        },
        // {
        //   name: 'logos',
        //   roles: ["super_admin","admin"],
        //   displayName: 'menu.logos',
        //   meta: {
        //     icon: "fa fa-file-image-o",
        //   },
        // },
        {
            name: 'championships',
            roles: ["super_admin", "admin"],
            displayName: 'menu.championships',
            meta: {
                icon: "fa fa-trophy",
            },
            children: [{
                    name: 'addChampionship',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.addChampionship',
                    meta: {
                        icon: "fa fa-plus",
                    },
                },
                {
                    name: 'requests',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.requests',
                    meta: {
                        icon: "fa fa-envelope",
                    },
                },
                {
                    name: 'ongoing',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.ongoing',
                    meta: {
                        icon: "fa fa-calendar",
                    },
                },
                {
                    name: 'upcoming',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.upcoming',
                    meta: {
                        icon: "fa fa-clock-o",
                    },
                },

            ],
        },
        {
            name: 'teams',
            roles: ["super_admin", "admin"],
            displayName: 'menu.teams',
            meta: {
                icon: "fa fa-flag-checkered",
            },
        },
        {
            name: 'appeals',
            roles: ["super_admin", "admin"],
            displayName: 'menu.appeals',
            meta: {
                icon: "fa fa-exclamation-triangle",
            },
        },
        {
            name: 'news',
            roles: ["super_admin", "admin", "editor"],
            displayName: 'menu.news',
            meta: {
                icon: "fa fa-newspaper-o",
            },
            children: [{
                    name: 'globalNews',
                    roles: ["super_admin", "admin", "editor"],
                    displayName: 'menu.globalNews',
                    meta: {
                        icon: "fa fa-globe",
                    },
                },
                {
                    name: 'localNews',
                    roles: ["super_admin", "admin", "editor"],
                    displayName: 'menu.localNews',
                    meta: {
                        icon: "fa fa-newspaper-o",
                    },
                },
            ]
        },
        {
            name: 'academy',
            roles: ["super_admin", "admin"],
            displayName: 'menu.academy',
            meta: {
                icon: "fa fa-futbol-o",
            },
            children: [{
                    name: 'information',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.information',
                    meta: {
                        icon: "fa fa-info",
                    },
                },
                {
                    name: 'services',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.services',
                    meta: {
                        icon: "fa fa-book",
                    },
                },
                {
                    name: 'academyNews',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.news',
                    meta: {
                        icon: "fa fa-newspaper-o",
                    },
                },
                {
                    name: 'sponsors',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.sponsors',
                    meta: {
                        icon: "fa fa-handshake-o",
                    },
                },
                {
                    name: 'subscribers',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.subscribers',
                    meta: {
                        icon: "fa fa-address-card-o",
                    },
                },

            ]
        },
        {
            name: 'users',
            roles: ["super_admin", "admin"],
            displayName: 'menu.users',
            meta: {
                icon: "fa fa-users",
            },
            children: [{
                    name: 'admins',
                    roles: ["super_admin"],
                    displayName: 'menu.admins',
                    meta: {
                        icon: "fa fa-info",
                    },
                },
                {
                    name: 'clients',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.clients',
                    meta: {
                        icon: "fa fa-book",
                    },
                },
                {
                    name: 'organizers',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.organizers',
                    meta: {
                        icon: "fa fa-newspaper-o",
                    },
                },
                {
                    name: 'referees',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.referees',
                    meta: {
                        icon: "fa fa-handshake-o",
                    },
                },
                // {
                //     name: 'coaches',
                //     roles: ["super_admin", "admin"],
                //     displayName: 'menu.coaches',
                //     meta: {
                //         icon: "fa fa-address-card-o",
                //     },
                // },
                {
                    name: 'editors',
                    roles: ["super_admin", "admin"],
                    displayName: 'menu.editors',
                    meta: {
                        icon: "fa fa-pencil-square-o",
                    },
                },

            ]
        },
        {
            name: 'my-chats',
            roles: ["editor"],
            displayName: 'menu.chat',
            meta: {
                icon: "fa fa-comments",
            },
        },
    ],
}