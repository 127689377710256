<template>
  <div class="app-layout">
    <navbar />
    <div class="app-layout__content">
      <div
        class="app-layout__sidebar-wrapper"
        :class="{ minimized: isSidebarMinimized }"
      >
        <div v-if="isFullScreenSidebar" class="d-flex justify--space-between">
          <logo />
          <va-button
            class="px-4 py-4"
            icon="close"
            flat
            color="dark"
            @click="onCloseSidebarButtonClick"
          />
        </div>
        <sidebar
          :width="sidebarWidth"
          :minimized="isSidebarMinimized"
          :minimizedWidth="sidebarMinimizedWidth"
        />
      </div>
      <div class="app-layout__page">
        <div class="layout fluid gutter--xl">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Navbar from "@/components/navbar/Navbar.vue";
import Logo from "@/components/vuestic-logo.vue";
import { gql, GraphQLClient } from "graphql-request";
import hash from "@/store/hash";

export default {
  name: "app-layout",

  components: {
    Navbar,
    Sidebar,
    Logo,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const mobileBreakPointPX = 640;
    const tabletBreakPointPX = 768;

    const sidebarWidth = ref("16rem");
    const sidebarMinimizedWidth = ref(undefined);

    const isMobile = ref(false);
    const isTablet = ref(false);
    const isSidebarMinimized = computed(() => store.state.isSidebarMinimized);
    const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX;
    const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX;

    const onResize = () => {
      store.commit("updateSidebarCollapsedState", checkIsTablet());

      isMobile.value = checkIsMobile();
      isTablet.value = checkIsTablet();
      sidebarMinimizedWidth.value = isMobile.value ? 0 : "4rem";
      sidebarWidth.value = isTablet.value ? "100%" : "16rem";
    };

    onMounted(async () => {
      window.addEventListener("resize", onResize);
      // Auto Login
      if (!localStorage.getItem("login")) {
        router.push({ name: "login" });
      } else {
        const loginData = JSON.parse(localStorage["login"]);
        const loginMutation = gql`
          mutation login($username: String, $password: String) {
            login(loginInput: { user: $username, password: $password }) {
              id
              token
              status
            }
          }
        `;
        const client = new GraphQLClient(store.state.appUrl, {
          headers: {
            authorization: "Bearer MY_TOKEN",
          },
        });
        let variables = {
          username: loginData.username,
          password: hash.decrypt(loginData.password),
        };
        try {
          const response = await client.request(loginMutation, variables);
          if (response.login.status) {
            variables = { id: response.login.id };
            const getUserData = gql`
              query ($id: Int) {
                Users(id: $id) {
                  id
                  email
                  name
                  password
                  user_role {
                    type
                  }
                }
              }
            `;
            const userData = await client.request(getUserData, {
              id: response.login.id,
            });
            const role = userData.Users[0].user_role.type;
            if (role == "admin" || role == "editor") {
              localStorage["userData"] = JSON.stringify(userData.Users[0]);
              store.dispatch("storeUserDataAfterLogin", userData.Users[0]);
            }
          } else {
            router.push({ name: "login" });
          }
        } catch (error) {
          console.error(error);
        }
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });

    onBeforeRouteUpdate(() => {
      if (checkIsTablet()) {
        // Collapse sidebar after route change for Mobile
        store.commit("updateSidebarCollapsedState", true);
      }
    });

    onResize();

    const isFullScreenSidebar = computed(
      () => isTablet.value && !isSidebarMinimized.value
    );

    const onCloseSidebarButtonClick = () => {
      store.commit("updateSidebarCollapsedState", true);
    };

    return {
      isSidebarMinimized,
      sidebarWidth,
      sidebarMinimizedWidth,
      isFullScreenSidebar,
      onCloseSidebarButtonClick,
    };
  },
};
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__navbar {
    min-height: 4rem;
  }

  &__content {
    display: flex;
    height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: var(--va-white);

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 999;
          background: linear-gradient(
            90deg,
            #1479b1 2%,
            #185bb5 37%,
            #01377f 85%
          ) !important;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          // Z-index fix for preventing overflow for close button
          z-index: -1;
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }
  &__page {
    flex-grow: 2;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 745px) {
  .layout {
    padding: 1rem;
  }
}
</style>
