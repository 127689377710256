import { VuesticPlugin } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import moment from "@/services/moment";
import firebaseMessaging from "./firebase";



const lang = localStorage.getItem('lang');
const i18nConfig = {
  locale: lang || 'sa',
  fallbackLocale: 'sa',
  messages: {
    gb: require('@/i18n/en.json'),
    sa: require('@/i18n/ar.json'),
  },
}


const app = createApp(App)
app.config.globalProperties.$messaging = firebaseMessaging;
app
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .directive('moment', moment)
  .component('v-quill', QuillEditor)




if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    id: process.env.VUE_APP_GTM_KEY,
    debug: false,
    vueRouter: router,
  }
  app.use(createGtm(gtmConfig))
}
app
  .use(createI18n(i18nConfig))
  .use(VuesticPlugin, vuesticGlobalConfig)
  .mount('#app')

