import { createStore } from 'vuex'

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  state: {
    firstOfMonth:new Date(`${new Date().getFullYear()+'-'+(new Date().getMonth()+1)}-01 00:00`),
    endDate: new Date(),
    isSidebarMinimized: false,
    userName: '',
    userData: {},
    appUrl: "https://assist.smart-node.net/api/graphql",
    imgUrl: "https://assist.smart-node.net/",
    newMessages:0,
    newChampionship:{creator_id:null,logo:'',name:'',teams_count:null,team_players_count:null,suggested_start_time: "" as any, type_id:null,playgrounds: [] as any,referees:[] as any,teams :[] as any}
  },
  mutations: {
    initNewMessages(state, payload){
      state.newMessages = payload
    },
    newMessages(state , _){
      state.newMessages++
    },
    storeUserDataAfterLogin(state,payload){
      state.userData = payload      
    },
    setUserData(state,payload){
      state.userData = payload
    },
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    setNewChampionship(state,payload){
      state.newChampionship=payload 
    },
    setNewchampionshipTeams(state,payload){
      payload.forEach(team => {
        if(!team.newTeam){
          if(team.name)
          delete team.name
          if(team.manger_id)
          delete team.manger_id
        }
        else{
          delete team.id
          delete team.newTeam
        }

      });
      console.log(state.newChampionship);
      
      state.newChampionship.teams = payload
    },
    setPlaygroundsReferees(state, payload) {
      state.newChampionship.playgrounds = payload.playgrounds
      state.newChampionship.referees = payload.referees
    },
    setSuggestedStartTime(state,payload){
      const day = payload.getDate(), month =payload.getMonth()+1, year = payload.getFullYear()
      state.newChampionship.suggested_start_time = `${year}-${month}-${day}`      
    },
    setDateRange(state , payload){
      state.firstOfMonth = payload.start
      state.endDate = payload.end
    }
  },
  getters: {
      getUrl(state){
        return state.appUrl.split(":8000/graphql")[0];
      },
    getNewChampionship(state){
      return state.newChampionship
    },
    getFirstOfMonth(state){
      return state.firstOfMonth;
    },
    getEndDate(state){
      return state.endDate;
    },
    getUserData(state){
      return state.userData;
    }
  },
  actions:{
    storeUserDataAfterLogin({commit},payload){
      commit('storeUserDataAfterLogin',payload)
    },
    encodeImageFileAsURL(context, payload) {
      if (Object.keys(payload)[0]=="logo") {
        let reader = new FileReader();
        reader.onloadend = function() {
          payload.self.img=reader.result
        }
        reader.readAsDataURL(payload.logo[0]);
      } else {
        const reader = new FileReader();
        reader.onloadend = function() {
          payload.self.playgrounds[payload.index].imgPreview=reader.result
        }
        reader.readAsDataURL(payload.img[0]);
      }
      
    },
    setNewChampionship({ commit },payload) {
      commit('setNewChampionship',payload)
    },
    setNewchampionshipTeams({ commit },payload){
      commit('setNewchampionshipTeams',payload)
    },
    setPlaygroundsReferees({ commit }, payload) {
      commit('setPlaygroundsReferees', payload)
    },
    setSuggestedStartTime({ commit },payload){
      commit('setSuggestedStartTime',payload)
    },
  }
})
