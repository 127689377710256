import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '@/layout/app-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'
// import navRoutes from '../components/sidebar/NavigationRoutes'
import jwtDecode from 'jwt-decode'
import RouteViewComponent from './route-view.vue'
// import UIRoute from '@/pages/admin/ui/route'

// Roles [2,3: Admin , 4: Editor]
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
      meta:{
        roles:[2,3,4],
      },
        component: () => import('@/pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'championships',
        path: 'championships',
        component: RouteViewComponent,
        meta:{
          roles:[2,3],
        },
        children: [
          {
            name: 'addChampionship',
            path: 'addChampionship',
            component: () => import('@/pages/admin/championships/AddChampionship.vue')
          },
          {
            name: 'requests',
            path: 'requests',
            component: () => import('@/pages/admin/championships/ChampionshipsRequests.vue')
          },
          {
            name: 'championshipRequestDetails',
            path: 'request/:id',
            component: () => import('@/pages/admin/championships/ChampionshipsRequestDetails.vue')
          },
          {
            name: 'ongoing',
            path: 'ongoing',
            component: () => import('@/pages/admin/championships/OngoingChampionships.vue')
          },
          {
            name: 'upcoming',
            path: 'upcoming',
            component: () => import('@/pages/admin/championships/UpcomingChampionships.vue')
          },
          {
            name: 'championshipDashboard',
            path: 'championship/:id',
            meta:{
              roles : [2,3,4]
            },
            component: () => import('@/pages/admin/championships/ChampionshipDashboard.vue')
          },
          
        ],
      },
      {
        name: 'teams',
        path: 'teams',
        component: () => import('@/pages/admin/teams/teams.vue'),
        meta:{
          roles:[2,3],
        },
      },
      {
        name: 'appeals',
        path: 'appeals',
        component: () => import('@/pages/admin/appeals/Index.vue'),
        meta:{
          roles:[2,3],
        },
      },
      {
        name: 'appeal',
        path: 'appeal/:id',
        component: () => import('@/pages/admin/appeals/AppealDetails.vue'),
        meta:{
          roles:[2,3],
        },
      },
      {
        name: 'team',
        path: 'team/:id',
        meta:{
          roles:[2,3],
        },
        component: () => import('@/pages/admin/teams/TeamDashboard.vue'),
      },
      {
        name: 'news',
        path: 'news',
        meta:{
          roles:[2,3,4],
        },
        component: RouteViewComponent,
        children:[
          {
            name: 'globalNews',
            path: 'Global-News',
            component: () => import('@/pages/admin/news/GlobalNews.vue')
          },
          {
            name: 'localNews',
            path: 'Local-News',
            component: () => import('@/pages/admin/news/LocalNews.vue')
          },

        ],
      },
      {
        name: 'academy',
        path: 'academy',
        meta : {
          roles:[2,3]
        },
        component: RouteViewComponent,
        children:[
          {
            name: 'information',
            path: 'information',
            component:() => import('@/pages/admin/academy/Information.vue')
          },
          {
            name: 'services',
            path: 'services',
            component:() => import('@/pages/admin/academy/Services.vue')
          },
          {
            name: 'academyNews',
            path: 'news',
            component:() => import('@/pages/admin/academy/News.vue')
          },
          {
            name: 'sponsors',
            path: 'sponsors',
            component:() => import('@/pages/admin/academy/Sponsers.vue')
          },
          {
            name: 'subscribers',
            path: 'subscribers',
            component:() => import('@/pages/admin/academy/Subscribers.vue')
          },
          
        ]
      },
      {
        name: 'users',
        path: 'users',
        meta:{
          roles:[2,3],
        },
        component: RouteViewComponent,
        children:[
          {
            name: 'admins',
            path: 'admins',
            meta:{
              roles:[3],
            },
            component:() => import('@/pages/admin/users/Admins.vue')
          },
          {
            name: 'clients',
            path: 'clients',
            component:() => import('@/pages/admin/users/Clients.vue')
          },
          {
            name: 'organizers',
            path: 'organizers',
            component:() => import('@/pages/admin/users/Organizers.vue')
          },
          {
            name: 'referees',
            path: 'referees',
            component:() => import('@/pages/admin/users/Referees.vue')
          },
          {
            name: 'coaches',
            path: 'coaches',
            component:() => import('@/pages/admin/users/Coaches.vue')
          },
          {
            name: 'editors',
            path: 'editors',
            component:() => import('@/pages/admin/users/Editors.vue')
          },
          
        ]
      },
      {
        name: "chat",
        path : 'My_Chats/:id',
        meta:{
          roles:[2,3,4],
        },
        component: () => import('@/pages/admin/chat/Chat.vue'),
      },
      {
        name: 'logos',
        path: '/logos',
        meta:{
          roles:[2,3]
        },
        component: () => import('@/pages/admin/logos/LogosRequests.vue'),
      },
      {
        name: 'profile',
        path: '/profile',
        meta:{
          roles:[2,3,4]
        },
        component: () => import('@/pages/admin/users/Profile.vue'),
      },
      // UIRoute,
    ]
  },
  {
    name:"editor",
    path: "/Editor/",
    component: AppLayout,
    children:[
      {
          name: 'my_champ',
          path: 'MyChampionships',
          meta:{
            roles:[4],
          },
          component: () => import('@/pages/admin/championships/MyChamp.vue'),
  
      },
      {
        name: "my-chats",
        path : 'My_Chats',
        meta:{
          roles:[4],
        },
        component: () => import('@/pages/admin/chat/MyChats.vue'),
      },
    ]
  },
  {
    name: 'login',
    path: '/login',
    meta:{
      roles:[2,3,4]
    },
    component: () => import('@/pages/auth/login/Login.vue'),
  },
  {
    name: 'recover-password',
    path: '/recover-password',
    meta:{
      roles:[2,3,4]
    },
    component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
  },
  {
    name: 'not-auth',
    path: '/not-Authorized',
    meta:{
      roles:[2,3,4]
    },
    component: () => import('@/pages/404-pages/NotAuth.vue'),

  },
  {
    path: '/:catchAll(.*)',
    component: Page404Layout,
    meta:{
      roles:[2,3,4]
    },
    children: [
      {
        name: 'not-found-large-text',
        path: '',
        component: () => import('@/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes,
})

router.beforeEach((to: any, _from, next) => {
  if(to.name != 'login' ){
    if(localStorage['token']){
      const userRole: any = jwtDecode<any>(localStorage['token']).role;    
      if(to.meta.roles?.includes(userRole)){
      return next()
    }
      else{
        next({ name : 'not-auth'})
      }
    }
    else{
      return next({name : 'login'})
    }
  }
  return next()

  
  
})

export default router
