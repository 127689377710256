import { io } from "socket.io-client";
import { Observable } from "rxjs";
import store from "../store";


const socket = io(store.state.appUrl.split('/api/graphql')[0], {
    query: {
        token: localStorage['token'],
        id: JSON.parse(localStorage["userData"] | '').id,
        fcm_token: localStorage.getItem("fcm_token") | null
    },
    transports: ['polling', 'websocket']
})

const listen = (roomName) => {
    socket.emit("Join", roomName);
    return new Observable((subscriber) => {
        socket.on(roomName, (data) => {
            subscriber.next(data)
        })
    })
}

const leave = (roomName) => {
    socket.emit("Leave", roomName);
}


const keyup = (data) => {
    socket.emit("keyup", data);
}

export default { listen, leave, keyup };