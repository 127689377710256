<template>
  <router-view />
</template>

<script>
import io from "@/services/web_socket";
import { gql, request, GraphQLClient } from "graphql-request";

import hash from "@/store/hash";
export default {
  data() {
    return {
      user_id: null,
      locale: "sa",
    };
  },
  async created() {
    
    localStorage["lang"] ? (this.locale = localStorage["lang"]) : (localStorage['lang']="sa");this.locale;
    this.toggleLang(this.locale);
    const fcm_token = await this.$messaging.getToken();
      if (fcm_token) {
        localStorage.setItem("fcm_token", fcm_token);
      }
    
    if (localStorage["userData"]) {
      this.user_id = JSON.parse(localStorage["userData"])?.id;
      // Auto login

      const loginMutation = gql`
        mutation login($username: String, $password: String) {
          login(loginInput: { user: $username, password: $password }) {
            id
            token
            status
          }
        }
      `;
      const client = new GraphQLClient(this.$store.state.appUrl, {});
      const loginData = JSON.parse(localStorage["login"]);

      let variables = {
        username: loginData.username,
        password: hash.decrypt(loginData.password),
      };

      try {
        const response = await client.request(loginMutation, variables);
        localStorage["token"] = response.login.token;
        if (response.login.status) {
          variables = { id: response.login.id };
          const getUserData = gql`
            query ($id: Int) {
              Users(id: $id) {
                id
                email
                name
                user_role {
                  type
                }
              }
            }
          `;
          const userData = await client.request(getUserData, {
            id: response.login.id,
          });
          const role = userData.Users[0].user_role.type;
          if (role == "admin" || role == "editor") {
            localStorage["userData"] = JSON.stringify(userData.Users[0]);
            this.$store.dispatch("storeUserDataAfterLogin", userData.Users[0]);
          }
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$swal
          .fire({
            icon: "info",
            title: "Oops... , Something went wrong !",
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }

      //

      const id = JSON.parse(localStorage["userData"]).id;
      io.listen(`newMessage${id}`).subscribe((id) => {
        if (this.$route.params.id != id) {
          this.$store.commit("newMessages", id);
        }
      });
      this.getUnreadMessages();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    async getUnreadMessages() {
      const UNREAD_MESSAGES = gql`
        query ($id: Int) {
          ChatReceiversCount(where: { receiver_id: $id, message_read: false }) {
            count
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        UNREAD_MESSAGES,
        { id: this.user_id }
      );
      this.$store.commit("initNewMessages", response.ChatReceiversCount.count);
    },
    toggleLang(locale) {
      const html = document.getElementById("html");
      const body = document.querySelector("body");
      if (locale == "sa") {
        html.setAttribute("lang", "ar");
        html.setAttribute("dir", "rtl");
        body.classList.add("ar");
        body.classList.remove("en");
      } else {
        html.setAttribute("lang", "en");
        html.setAttribute("dir", "ltr");
        body.classList.remove("ar");
        body.classList.add("en");
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/sass/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;700;800&family=Montserrat:wght@400;500;600;700;800&display=swap");
@import "~@/css/app.css";
</style>
