<template>
<div class="d-flex">
    <img src="/img/AFS-logo.png" style="width:85px;padding: 10px" alt="">
    <img src="/img/AFS-text.png" style="width:130px;padding: 15px 0;" alt="">
</div>  
</template>
<script>
import { useColors } from "vuestic-ui";

export default {
  name: "VaIconVuestic",

  props: {
    height: { type: [Number, String], default: 16 },
    color: { type: [String], default: "primary" },
  },

  computed: {
    colorsComputed() {
      const { getColor, shiftHSLAColor } = useColors();
      const color = getColor(this.color, "primary");

      return { start: color, end: shiftHSLAColor(color, { l: -20 }) };
    },
  },
};
</script>
