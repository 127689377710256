import CryptoJS from "crypto-js";

const getKeyAndIV = (password) => {
    const iterations = 234;
    let text = "hi there"
    while (text.length < 22) { text = text + "0" }
    const salt = CryptoJS.enc.Base64.parse(text);
    const iv128Bits = CryptoJS.PBKDF2(password, salt, { keySize: 128 / 32, iterations: iterations });
    const key256Bits = CryptoJS.PBKDF2(password, salt, { keySize: 256 / 32, iterations: iterations });

    return {
        iv: iv128Bits,
        key: key256Bits
    };
};

const skey = getKeyAndIV('rsport123');
const encrypt = function (message) {
    return CryptoJS.AES.encrypt(message, skey.key, { iv: skey.iv }).ciphertext.toString(CryptoJS.enc.Base64);
}


const decrypt = function (message) {
    const params = {
        ciphertext: CryptoJS.enc.Base64.parse(message),
        salt: ""
    };
    return CryptoJS.AES.decrypt(params, skey.key, { iv: skey.iv }).toString(CryptoJS.enc.Utf8)
}

export default { encrypt, decrypt };