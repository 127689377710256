<template>
  <va-dropdown class="message-dropdown" position="bottom" :offset="[0, 13]">
    <template #anchor>
      <va-badge :text="$store.state.newMessages" overlap>
        <va-icon-message
          class="message-dropdown__icon message-dropdown__icon--unread"
        />
      </va-badge>
    </template>

    <va-dropdown-content class="message-dropdown__content pl-4 pr-4 pt-2 pb-2">
      <div v-if="myChats.length > 0">
        <div
          v-for="chat in myChats"
          :key="chat.id"
          class="message-dropdown__item row pt-1 pb-1 mt-2 mb-2"
          @click="goToChat(chat.id)"
        >
          <div
            v-if="chat.unread"
            :class="{ 'message-dropdown__item--unread': chat.unread }"
          >
            <img
              :src="$store.state.imgUrl + chat.logo"
              class="message-dropdown__item__avatar mr-2"
            />
            <span class="ellipsis px-3" style="max-width: 85%">
              {{ chat.name }}
            </span>
          </div>
        </div>
      </div>
      <div v-else class="message-dropdown__item row pt-1 pb-1 mt-2 mb-2">
        <h6>{{ $t("no_messages") }}</h6>
      </div>
    </va-dropdown-content>
  </va-dropdown>
</template>

<script>
import VaIconMessage from "@/components/icons/VaIconMessage";
import { useGlobalConfig } from "vuestic-ui";
import { gql, request } from "graphql-request";

export default {
  name: "message-dropdown",
  components: {
    VaIconMessage,
  },
  data() {
    return {
      myChats: [],
      user_id: JSON.parse(localStorage["userData"]).id,
    };
  },
  props: {
    chats: {
      type: Array,
      default: () => [
        {
          name: "new",
          details: {
            name: "Oleg M",
            avatar: "https://picsum.photos/24?image=1083",
          },
          unread: true,
          id: 1,
        },
        {
          name: "new",
          details: {
            name: "Andrei H",
            avatar: "https://picsum.photos/24?image=1025",
          },
          unread: true,
          id: 2,
        },
      ],
    },
  },
  methods: {
    async getUnreadMessages() {
      const MY_CHATS = gql`
        query ($id: Int) {
          unReadChampMessages(id: $id) {
            id
            name
            logo
            unread
          }
        }
      `;
      try {
        const response = await request(this.$store.state.appUrl, MY_CHATS, {
          id: JSON.parse(localStorage["userData"]).id,
        });
        this.myChats = response.unReadChampMessages;
        console.log(this.myChats);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },
    async goToChat(champ_id) {
      const READ_CHAMP_MESSAGES = gql`
        mutation ($id: Int, $champ_id: Int) {
          readChampMessages(id: $id, champ_id: $champ_id) {
            status
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        READ_CHAMP_MESSAGES,
        {
          id: this.user_id,
          champ_id,
        }
      );
      if (response.readChampMessages.status) {
        const UNREAD_MESSAGES = gql`
          query ($id: Int) {
            ChatReceiversCount(
              where: { receiver_id: $id, message_read: false }
            ) {
              count
            }
          }
        `;
        const response = await request(
          this.$store.state.appUrl,
          UNREAD_MESSAGES,
          { id: this.user_id }
        );
        this.$store.commit(
          "initNewMessages",
          response.ChatReceiversCount.count
        );
        this.$router.push({ name: "chat", params: { id: champ_id } });
      }
    },
    markAllAsRead() {
      this.computedOptions = this.computedOptions.map((item) => ({
        ...item,
        unread: false,
      }));
    },
  },
  mounted() {
    this.getUnreadMessages();
  },
};
</script>

<style lang="scss">
.message-dropdown {
  cursor: pointer;

  .message-dropdown__icon {
    position: relative;
    display: flex;
    align-items: center;

    &--unread::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: -0.5rem;
      background-color: var(--va-danger);
      height: 0.375rem;
      width: 0.375rem;
      margin: 0 auto;
      border-radius: 0.187rem;
    }
  }

  &__item {
    cursor: pointer;
    margin-bottom: 0.75rem;
    position: relative;
    flex-wrap: nowrap;

    &--unread {
      color: var(--va-gray);

      &::after {
        content: "";
        position: absolute;
        right: -15px;
        top: 15px;
        bottom: 0;
        height: 0.375rem;
        width: 0.375rem;
        background-color: var(--va-danger);
        margin: auto;
        border-radius: 0.187rem;
      }
      body.ar &::after {
        left: 0;
        right: unset;
      }
    }

    &:hover {
      color: var(--va-primary);
    }

    &__avatar {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
