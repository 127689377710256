<template>
  <div class="app-layout__navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <va-icon-menu-collapsed
            @click="isSidebarMinimized = !isSidebarMinimized"
            :class="{ 'x-flip': isSidebarMinimized }"
            class="va-navbar__item"
            color="#fff"
          />
        </div>
        <router-link to="/">
          <vuestic-logo class="logo" />
        </router-link>
      </template>

      <template #right>
        <app-navbar-actions
          style="color: white; fill: white"
          class="app-navbar__actions md5 lg4"
          :user-name="userData.name"
        />
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { useColors } from "vuestic-ui";
import { useStore } from "vuex";
import { computed } from "vue";
import VuesticLogo from "@/components/vuestic-logo";
import VaIconMenuCollapsed from "@/components/icons/VaIconMenuCollapsed";
import AppNavbarActions from "./components/AppNavbarActions.vue";

export default {
  components: { VuesticLogo, AppNavbarActions, VaIconMenuCollapsed },
  setup() {
    const { getColors } = useColors();
    const colors = computed(() => getColors());
    const store = useStore();

    const isSidebarMinimized = computed({
      get: () => store.state.isSidebarMinimized,
      set: (value) => store.commit("updateSidebarCollapsedState", value),
    });

    const userData = computed(() => store.state.userData);
    return {
      colors,
      isSidebarMinimized,
      userData,
    };
  },
};
</script>

<style lang="scss" scoped>
.va-navbar {
  background: linear-gradient(
    22deg,
    #1479b1 2%,
    #185bb5 37%,
    #01377f 85%
  ) !important;
  height: 5rem;
  box-shadow: var(--va-box-shadow);
  z-index: 2;
  @media (max-width: 767.98px) {
    & {
      height: 9rem;
      padding: 0.5rem 1rem;
    }
  }
  &__center {
    @media screen and (max-width: 1200px) {
      .app-navbar__github-button {
        display: none;
      }
    }
    @media screen and (max-width: 950px) {
      .app-navbar__text {
        display: none;
      }
    }
  }
  &__left {
    justify-content: space-between !important;
  }

  @media screen and (max-width: 950px) {
    .left {
      width: 100% !important;
    }
    .app-navbar__actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
}

.x-flip {
  transform: scaleX(-100%);
}

.app-navbar__text > * {
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
}
</style>
