import moment from "moment";

export default { 
    mounted : (el,bind)=>{
        const now = moment(new Date())
        const messageDate = bind.value.date
        const timeDiffrenceInDays = moment.duration(now.diff(messageDate))._data.days
        if( timeDiffrenceInDays > 0){
            moment.locale(bind.value.locale)
            el.innerText = moment(messageDate).format("MM/DD h:mm a")  
        }
        else{
            moment.locale(bind.value.locale)
            el.innerText = moment(messageDate).startOf('minute').fromNow() 
        }
    }
}