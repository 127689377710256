
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
    apiKey: "AIzaSyDh2dddthprpWwRekkcVgfajhSrKG9j3wk",
    authDomain: "r-sport-3cb42.firebaseapp.com",
    projectId: "r-sport-3cb42",
    storageBucket: "r-sport-3cb42.appspot.com",
    messagingSenderId: "645160963771",
    appId: "1:645160963771:web:a018456451b04751e1e39e",
    measurementId: "G-YR9LD7EMHH"
}

firebase.initializeApp(firebaseConfig)

export default firebase.messaging();